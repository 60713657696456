.homeBody {
    background-color: #242424;
    color: black;
    height: 100vh;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      /* height: 100vh; */
      height: auto;
    }
}

.aboutMeBody {
    @media (max-width: 768px) {
      height: auto;
    }
}
.homeBodyLeft {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
}

.homeBodyRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    @media (max-width: 768px) {
      width: 100%;
      overflow: none;
    }
}
.sectionSeparator {
  margin: 15px;
}
.myResume{
  display: flex;
  align-items: center;
}

.statusLive {
  margin: 10px;
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(88, 88, 88); 
  border-radius: 50%;
  /* overflow: hidden; */
}
.greenCircle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #4ab84f;
  border-radius: 50%;
  animation: spread 2s linear infinite;
  transform-origin: center;
}
@keyframes spread {
  0% {
      transform: scale(.2);
  }
  50% {
      transform: scale(1);
  }
  100% {
      transform: scale(.2);
      opacity: 0;
  }
}

.AboutMe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: whitesmoke;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.AboutMeLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 120px;
  height: 80%;
  width: 50%;
  @media (max-width: 768px) {
    padding: 0px;
    width: 100%;
  }
}
.introSection {
  display: flex;
  flex-direction: column;
}
.writeupSection {
  font-size: 25px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 20px;
    padding-left: 20px;
  }
}
.h2Style {
  @media (max-width: 768px) {
    font-size: 15;
    padding-left: 20px;
  }
}
.menuSection {
  color: '#806eff';
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
}
.menuSection div {
  margin-bottom: 10px;
  /* cursor: pointer; */
  transition: background-color 0.3s ease;
}

.menuItem {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  display: inline-block; 
}
.horizontalLine {
  position: absolute;
  padding: 1px;
  /* margin-right: 2px; */
  top: 50%;
  left: 0;
  width: 20px;
  height: 1px;
  background-color: white;
  transition: width 0.3s ease;
}

.menuItem:hover {
  color: #6972cd;
}
.activeMenu {
  color: #6972cd;
}
.menuItemText {
  position: relative;
  z-index: 1;
  margin-left: 10px;
}
.AboutMeRight {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}
.iconContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  animation: floatAnimation 3s infinite ease-in-out alternate;
}
.iconContainer:hover {
  animation-play-state: paused;
}
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.icon {
  /* margin-right: 10px; */
  font-size: 20px;
  color: #a5a5a5;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}
.icon:hover {
  color: #6972cd;
}
.margin10 {
  margin: 5px;
  font-size: 25px;
}

.rotateIcon {
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}
.whiteColorText {
    color: white;
}

.pageHeading {
    --s: 0.1em;   /* the thickness of the line */
    --c: #00c983; /* the color */
    
    color: #ffffff00;
    padding-bottom: var(--s);
    background: 
      linear-gradient(90deg,var(--c) 50%,#ffffff 0) calc(100% - var(--_p,0%))/200% 100%,
      linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
    -webkit-background-clip: text,padding-box;
            background-clip: text,padding-box;
    transition: 0.5s;
    font-family: system-ui, sans-serif;
    /* cursor: pointer; */
    font-size: 3rem;
    margin: 0;
    display: inline-block;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 2rem;
      padding-left: 20px;
      padding-top: 20px;
    }
}
.pageHeading:hover {--_p: 100%}
.linkStyle {
  margin: 4px;
}
.companyCards {
  display: flex;
  flex-direction: column;

  .expCompanyCard {
      margin: 20px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      transition: transform 0.1s ease-in-out; 
      /* border: 1px solid white; */
      border-radius: 10px;
      background: #242424;
      /* box-shadow:  2px 2px 3px #111111,
                    -2px -2px 3px #373737; */
      &:hover {
        transform: translateY(-10px) translateX(-10px);
        box-shadow:  3px 3px 5px #6972cd,
                    -3px -3px 5px #043866;
        border-radius: 10px;
      }
      &:hover .companyName {
        color: #6972cd;
      }
      &:hover .rotateIcon {
        color: #9EC8B9;
        transform: rotate(45deg) translateY(-5px); 
      }
      &:hover .chipStyle {
        color: #6972cd;
      }
  }
  @media (max-width: 768px) {
    .expCompanyCard {
      box-shadow:  2px 2px 3px #111111,
                  -2px -2px 3px #373737;
      scroll-behavior: smooth;
      &:hover {
        box-shadow:  2px 2px 3px #111111,
                  -2px -2px 3px #373737;
      } 
    }
  }
  .companyName {
    font-size: 30px;
    font-weight: 600;
    color: white;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .companyName a {
    text-decoration: none; 
  }
  .companyRole {
    color: #fefefe;
    font-size: 18px;
    margin: 2px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .companyPeriod, 
  .companyLocation {
    color: #fefefe;
    font-size: 12px;
    margin: 2px;
  }
  .companyDesc {
    font-size: 16px;
    color: #fefefe;
    margin: 2px;
  }
  .chipStyle {
    color: #8a8a8a;
    margin: 8px;
    font-weight: 600;
  }
}

.headerBar {
  display: none;
  @media (max-width: 768px) {
    margin: 0px 20px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    display: block;
    position: sticky;
    top: 0;
    z-index: 100; 
  }
}
  
.specialText {
	color: white;
  background: linear-gradient(150deg, #6c2ba1, #6c2ba1) no-repeat;
  background-position: bottom;
  background-size: 100% 75%;
  font-size: 18px;
  margin: 0px 2px;
  font-weight: 400;
}

.themeColor { 
  color: #6c2ba1;
}
